<template>
  <div class="animated fadeIn">
    <b-card-group columns class="card-columns">
      <b-card header="Pie Chart">
        <div class="chart-wrapper">
          <pie-example chartId="chart-pie-01"/>
        </div>
      </b-card>
    </b-card-group>
  </div>
</template>
<script>
import PieExample from './charts/PieExample'

export default {
  name: 'charts',
  components: {
    PieExample,
  }
}
</script>
